import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  notificationMessage: '',
  notificationType: '',
  notificationHeader: ''
}

const changeState = (state = initialState, { type, ...rest }) => {
  console.log(rest)
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    case 'SET_NOTIFICATION_TYPE':
      return { ...state, notificationType: rest.notificationType };
    case 'SET_NOTIFICATION_HEADER':
      return { ...state, notificationHeader: rest.notificationHeader };
    case 'SET_NOTIFICATION_MESSAGE':
      return { ...state, notificationMessage: rest.message };
    default:
      return state;
  }
};

const store = createStore(changeState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
export default store