export const articles = `https://${process.env.REACT_APP_API}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/sbx/articles`
export const article_tags = `https://${process.env.REACT_APP_API}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/sbx/articles/tags`


export const article_import = `https://${process.env.REACT_APP_API}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/sbx/article_import`

export const entra_app_id = process.env.REACT_APP_ENTRA_APP_ID
export const entra_authority = process.env.REACT_APP_ENTRA_AUTHORITY
export const q_assistant_id = process.env.REACT_APP_Q_ASSISTANT_ID
export const ccp_url = process.env.REACT_APP_CCP_URL
export const instance_url = process.env.REACT_APP_INSTANCE_URL
export const connect_login_url = process.env.REACT_APP_CONNECT_LOGIN_URL
export function transformDynamoDBJSON(data) {
    function transformValue(value) {
        if (value === undefined || value === null) return null;
        if (typeof value !== 'object') return value;

        if ('S' in value) return value.S;
        if ('N' in value) return Number(value.N);
        if ('BOOL' in value) return value.BOOL;
        if ('NULL' in value) return null;
        if ('M' in value) return transformMap(value.M);
        if ('L' in value) return value.L.map(transformValue);
        if ('SS' in value) return value.SS;
        if ('NS' in value) return value.NS.map(Number);
        if ('BS' in value) return value.BS;

        return transformMap(value);
    }

    function transformMap(map) {
        const result = {};
        for (const key in map) {
            result[key] = transformValue(map[key]);
        }
        return result;
    }

    // Directly return the transformed Items array
    if (Array.isArray(data.Items)) {
        return data.Items.map(transformMap);
    } else if (Array.isArray(data)) {
        // If the input is already an array, transform it directly
        return data.map(transformMap);
    } else {
        // If it's neither an array nor has an Items property, return an empty array
        console.warn('Unexpected data structure in transformDynamoDBJSON');
        return [];
    }
}