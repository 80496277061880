import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './config/Redux/store'
import {MsalProvider} from "@azure/msal-react";
import {msalConfig} from "./config/authConfig";
import {PublicClientApplication} from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <Provider store={store}>
        <React.StrictMode>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </React.StrictMode>
    </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()